// https://confluence.sportradar.ag/display/LO/LBC+-+Filter+details

import { BOOKING_STATUS } from './shoppingBasket';

export default {
    availabilities: {
        MY_MATCHES: 201,
        AVAILABLE_MATCHES: 202,
        BUYABLE_MATCHES: 203,
        UNAVAILABLE_MATCHES: 204,
        RECOMMENDED_MATCHES: 205,
    },
    availabilityStatusMap: {
        201: BOOKING_STATUS.BOOKED,
        202: BOOKING_STATUS.BOOKABLE,
        203: BOOKING_STATUS.BUYABLE,
        204: BOOKING_STATUS.NOT_AVAILABLE,
    },
    lcBookingStatus: {
        LCO_MY: BOOKING_STATUS.BOOKED,
        LCO_AVAILABLE: BOOKING_STATUS.BOOKABLE,
        LCO_UNAVAILABLE: BOOKING_STATUS.NOT_AVAILABLE,
        LCO_BUYABLE: BOOKING_STATUS.BUYABLE,
        LCO_RECOMMENDED: BOOKING_STATUS.RECOMMENDED,
        LCR_MY: BOOKING_STATUS.BOOKED,
        LCR_AVAILABLE: BOOKING_STATUS.BOOKABLE,
        LCR_UNAVAILABLE: BOOKING_STATUS.NOT_AVAILABLE,
        LCR_BUYABLE: BOOKING_STATUS.BUYABLE,
        LCR_RECOMMENDED: BOOKING_STATUS.RECOMMENDED,
        LCT_MY: BOOKING_STATUS.BOOKED,
        LCT_AVAILABLE: BOOKING_STATUS.BOOKABLE,
        LCT_UNAVAILABLE: BOOKING_STATUS.NOT_AVAILABLE,
        LCT_BUYABLE: BOOKING_STATUS.BUYABLE,
        LCT_RECOMMENDED: BOOKING_STATUS.RECOMMENDED,
    },
    availabilityReason: {
        NOT_SET: 200,
        MATCH_BOOKED: 201,
        MATCH_AVAILABLE: 202,
        MATCH_UNAVAILABLE: 203,
        MATCH_INACTIVE: 204,

        SPORT_TOURNAMENT_PACKAGE: 210,
        EXCLUSIVE: 211,
        RTS_MATCH: 213,
        TEST_MATCH: 214,
        LIVEODDS_TV_PACKAGE: 215,
        LIVEODDS_SCOUT_PACKAGE: 216,
        LIVEODDS_PACKAGE: 221,
        LIVEDATA_PACKAGE: 217,
        INACTIVE: 218,
        UNKNOWN: 219,
        STREAMING_ONLY: 220,
        STREAMING_ONLY_NO_PRODUCT: 222,
        UNIFIED_FEED_ONLY: 223,
        MISSING_PACKAGE: 224,
        NOT_OFFERED: 225,
        EVENT_ENDED: 300,
        FLEX_FULL: 51,
    },
    coveredFrom: {
        TV: 301,
        VENUE: 303,
    },
    vbpType: {
        STAR: 2,
        MVP: 1,
        Other: 0,
    },
    coveredWith: {
        LIVEODDS: 401,
        SCOUT_BASIC: 403,
        SCOUT_DEEPER: 404,
        RTS: 411,
        LIVE_CHANNEL_RETAIL: 407,
        LIVE_CHANNEL_ONLINE: 408,
        LIVE_CHANNEL_TRADING: 409,
        LIVE_CHANNEL_STANDARD: 601,
        LIVE_CHANNEL_MIXED: 602,
        LIVEODDS_EXTENDED: 410,
        BETPAL_FOLLOW_LO: 500,
        BETPAL_MANUAL: 501,
        LIVE_CHANNEL_STANDARD_QUALITY: 'Standard Quality',
        LIVE_CHANNEL_HIGH_QUALITY: 'High Quality',
        PREMIUM_FEED: 'Premium Feed',
        STANDARD_FEED: 'Standard Feed',
    },

    ageGroups: {
        Senior: 'Senior',
        U23: 'U23',
        U22: 'U22',
        U21: 'U21',
        U20: 'U20',
        U19: 'U19',
        U18: 'U18 and Below',
        Unconfirmed: 'Unconfirmed',
    },
    ageGroupsOrder: {
        Senior: 7,
        U23: 6,
        U22: 5,
        U21: 4,
        U20: 3,
        U19: 2,
        U18: 1,
        Unconfirmed: 8,
    },
    sportType: {
        real: 'real',
        simulated: 'simulated',
    },
    autobookingTypes: {
        ALL: 'All',
        ACTIVE: 'Active',
        EXPIRED: 'Expired',
        // DELETED: 'Deleted',
    },
    map: {
        1: { char: 's' },
        2: { char: 'b' },
        3: { char: 'a' },
        4: { char: 'i' },
        5: { char: 't' },
        6: { char: 'h' },
        12: { char: 'r' },
        13: { char: '9' },
        16: { char: 'f' },
        19: { char: 'e' },
        20: { char: 'g' },
        21: { char: 'c' },
        22: { char: 'd' },
        23: { char: 'v' },
        29: { char: 'j' },
        31: { char: 'k' },
        32: { char: 'l' },
        34: { char: 'm' },
        37: { char: 'n' },

        109: { char: 'o' },
        110: { char: 'p' },
        111: { char: '6' },

        201: { char: 'q' },
        202: { char: '7' },
        203: { char: 'u' },
        204: { char: '8' },

        301: { char: 'w' },
        303: { char: 'x' },

        401: { char: 'y' },
        406: { char: 'z' },
        410: { char: '1' },

        403: { char: '2' },
        404: { char: '3' },

        408: { char: '4' },
        407: { char: '5' },
    },
};
