import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import './collapsibleRow.scss';
import {
    PdsCheckboxSelectedMdIcon,
    PdsCheckboxUnselectedMdIcon,
    PdsVideoOnIcon,
    PdsChevronDownSmIcon,
    PdsChevronUpSmIcon,
} from '@podium-design-system/react-icons';

const CollapsibleRow = ({ isDefault, name, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <div className="collapsible-row">
            <div
                className={classnames('header', { collapsible: isCollapsed })}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <div className="collapsible-row-title">
                    {isDefault ? (
                        <PdsCheckboxSelectedMdIcon />
                    ) : (
                        <PdsCheckboxUnselectedMdIcon />
                    )}
                    <PdsVideoOnIcon className="icon-video" />
                    <div className="title text-semibold">{name}</div>
                </div>
                <div className="indicators">
                    {isCollapsed && buildChevron(isCollapsed)}
                </div>
            </div>
            {!isCollapsed && <div className="content">{children}</div>}
        </div>
    );
};

CollapsibleRow.propTypes = {
    isDefault: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
};

function buildChevron(collapsed) {
    return collapsed ? <PdsChevronDownSmIcon /> : <PdsChevronUpSmIcon />;
}

export default CollapsibleRow;
