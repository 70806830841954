import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import constants from '../../../../../constants/Constants';
import {
    BOOKING_STATUS,
    adapterIds,
    adapterLcProducts,
    eventAction,
    eventReason,
} from '../../../../../constants/shoppingBasket';
import {
    adapterButtons,
    canBookWithCurrentAdapter,
    eventsSorted,
    selectedAdapter,
    shouldFetchPrices,
} from '../../../../../selectors/shoppingBasket';
import {
    bookEvents,
    clearFailedBookings,
    dismissErrorsDialog,
    loadPrices,
    openBetpalBooking,
    removeAllEvents,
    removeEvent,
    setContentVariant,
    setIsExpanded,
    setQuality,
    setSelectedAdapterId,
} from '../../../../../stores/shoppingBasket/actions';
import accounting from '../../../../../utils/Accounting';
import { isSubProductOptionalContent } from '../../../../../utils/audioVisualUtils';
import {
    createLabelForAugStreaming,
    hasProduct,
    isConsideredBooked,
    tooLateToBook,
} from '../../../../../utils/basketUtils';
import Alert from '../../../../Alert/Alert';
import Badge from '../../../../Badge/Badge';
import BusySpinner from '../../../../BusySpinner/BusySpinner';
import Event from '../../../../Event/Event';
import Button from '../../../../formInput/Button/Button.jsx';
import CustomSelect from '../../../../formInput/CustomSelect/CustomSelect';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import './ShoppingBasketContent.scss';

class ShoppingBasketContent extends React.Component {
    _onRemoveAllClick = () => {
        this.props.removeAllEvents();
    };

    _onCancelClick = () => {
        if (this.props.prices && this.props.selectedAdapter.isBooking) {
            this.props.removeAllEvents(this.props.failedBookings);
            this.props.clearFailedBookings();
        }
        this.props.setIsExpanded(false);
    };

    _removeEventFromBasket = eventUri => {
        if (
            this.props.selectedAdapter.error &&
            this.props.basketErrorsDismissed
        ) {
            this._loadPrices();
        }
        this.props.removeEvent(eventUri);
    };

    _loadPrices() {
        this.props.loadPrices(
            this.props.selectedAdapter.id,
            this.props.eventsSorted
        );
    }

    componentDidMount() {
        if (this.props.shouldFetchPrices) {
            this._loadPrices();
        }
    }

    componentDidUpdate() {
        if (this.props.shouldFetchPrices) {
            this._loadPrices();
        }
    }

    _switchToCheckoutAdapter = adapterUid => {
        this.props.setSelectedAdapterId(adapterUid);
    };

    _dismissErrorsDialog = () => {
        this.props.dismissErrorsDialog();
        // check if we need to load prices
        this._loadPrices();
    };

    /* Check if given event is bookable
     * @return boolean */
    _eventIsBookable(eventUri) {
        if (this.props.selectedAdapter.isLoading) {
            return false;
        }

        const event = this.props.eventsSorted.find(
            event => event.uri === eventUri
        );

        if (tooLateToBook(event)) {
            return false;
        }

        if (this.props.selectedAdapter.id === adapterIds.BETPAL) {
            return (
                hasProduct(event.products, adapterIds.BETPAL) &&
                !isConsideredBooked(event.products, adapterIds.BETPAL)
            );
        }
        if (this.props.prices && this.props.prices.hasOwnProperty(eventUri))
            return (
                !this.props.prices[eventUri]?.error &&
                this.props.prices[eventUri]?.state === BOOKING_STATUS.BOOKABLE
            );
    }

    _getBookableEventsByAdapter(adapterButton) {
        return this.props.eventsSorted.filter(event =>
            adapterButton.products.some(
                product =>
                    event.products[product] &&
                    event.products[product].bookingStatusId === 1
            )
        ).length;
    }

    _getEventPrice(eventUri, defaultValue = 0) {
        if (this.props.prices && this.props.prices.hasOwnProperty(eventUri)) {
            return this.props.prices[eventUri].price;
        }
        return defaultValue;
    }

    _hasPriceChange(eventUri) {
        if (this.props.prices && this.props.prices.hasOwnProperty(eventUri)) {
            return (
                this.props.prices[eventUri].reason ===
                eventReason.BOOKABLE_PRICE_CHANGE
            );
        }
        return false;
    }

    _getEventProduct(eventUri) {
        if (this.props.prices && this.props.prices.hasOwnProperty(eventUri)) {
            switch (this.props.prices[eventUri].reason) {
                case 10: // bookable all
                case 20: // booked all
                    return 'LO/LD';
                case 11: // bookable LO
                case 21: // booked LO
                    return 'LO';
                case 12: // bookable LD
                case 22: // booked LD
                    return 'LD';
                default:
                    return '';
            }
        }
        return '';
    }

    _getBookableFncLabel(eventPrice, fncCreateResult) {
        const valueNotFound = '?';
        if (!this.props.selectedAdapter.showPrices) {
            return fncCreateResult('', '');
        }
        return fncCreateResult(
            _.isNumber(eventPrice.basePriceInEuros)
                ? eventPrice.basePriceInEuros > 0
                    ? 'Extra fee'
                    : 'In package'
                : valueNotFound,
            '',
            true
        );
    }

    /**
     * Returns an object with two values, that can be used for displaying.
     * { fee: A, surcharge: B }
     */
    _getPriceColumnValues(event) {
        const fncCreateResult = (fee, surcharge = '') => {
            return { surcharge, fee };
        };

        const fncCreateLabel = (className, text, tooltipText) => {
            const label = (
                <div className={`label ${className}`} title={tooltipText}>
                    {text}
                </div>
            );
            return fncCreateResult(label);
        };

        const getLabelForAugStreaming = products => {
            const label = createLabelForAugStreaming(
                products,
                this.props.selectedAdapter.id
            );
            return fncCreateLabel('label-booked', label);
        };

        if (this.props.selectedAdapter.isLoading) {
            return fncCreateResult('..');
        }

        if (this.props.selectedAdapter.error) {
            return fncCreateResult(
                <div className="label label-error">Not available</div>
            );
        }

        if (
            !this.props.prices ||
            !this.props.prices.hasOwnProperty(event.uri)
        ) {
            // If we don't  have a price for a event, that should mean that the event is considered booked
            if (
                isConsideredBooked(
                    event.products,
                    this.props.selectedAdapter.id
                )
            ) {
                return fncCreateLabel('label-booked', 'booked');
            } else if (
                !hasProduct(event.products, this.props.selectedAdapter.id)
            ) {
                return fncCreateLabel('label-not-bookable', 'Not bookable');
            } else if (
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-4sight`
                ) ||
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-as`
                )
            ) {
                return getLabelForAugStreaming(event.products);
            } else {
                return fncCreateResult('?');
            }
        }

        const eventPrice = this.props.prices[event.uri];
        if (eventPrice === undefined) {
            return fncCreateLabel(
                'label-error',
                'Prices are not available for this product.'
            );
        } else if (eventPrice.action === eventAction.SUCCESS) {
            if (
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-4sight`
                ) ||
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-as`
                )
            ) {
                return getLabelForAugStreaming(event.products);
            }
            // Event was booked
            return fncCreateLabel('label-booked', 'booked');
        } else if (eventPrice.action === eventAction.FAIL) {
            // Booking failed
            return fncCreateLabel('label-error', 'error', eventPrice.message);
        }

        if (eventPrice.state === BOOKING_STATUS.BOOKABLE) {
            if (tooLateToBook(event)) {
                return fncCreateLabel(
                    'label-not-bookable',
                    'Not bookable (ended)'
                );
            } else if (
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-4sight`
                ) ||
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-as`
                )
            ) {
                return getLabelForAugStreaming(event.products);
            }
            return this._getBookableFncLabel(eventPrice, fncCreateResult);
        } else if (eventPrice.state === BOOKING_STATUS.BOOKED) {
            if (
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-4sight`
                ) ||
                event.products.hasOwnProperty(
                    `${adapterLcProducts[this.props.selectedAdapter.id]}-as`
                )
            ) {
                return getLabelForAugStreaming(event.products);
            }
            return fncCreateLabel('label-booked', 'booked', eventPrice.message);
        } else {
            return fncCreateLabel(
                'label-not-bookable',
                'Not bookable',
                eventPrice.message
            );
        }
    }

    _numberFormat(number, zeroAsDash = false) {
        if (_.isNumber(number)) {
            if (number === 0 && zeroAsDash) {
                return '-';
            }
            return accounting.formatMoney(number);
        }
        return number;
    }

    _buildAdapterButtonRow() {
        const adapterButtonsList = this.props.adapterButtons.map(
            adapterButton => {
                const className = classnames('adapter-button', {
                    selected:
                        adapterButton.id === this.props.selectedAdapter.id,
                });

                return (
                    <div
                        key={`basketTab_${adapterButton.id}`}
                        className={className}
                        onClick={this._switchToCheckoutAdapter.bind(
                            this,
                            adapterButton.id
                        )}
                    >
                        {adapterButton.name}
                        <Badge
                            className="textline-300"
                            content={this._getBookableEventsByAdapter(
                                adapterButton
                            )}
                            color="blue"
                        />
                    </div>
                );
            }
        );

        return (
            <div className="row header-row no-bottom-padding border-bottom">
                {adapterButtonsList}
            </div>
        );
    }

    _onBookClicked(bookableEventUris) {
        if (this.props.selectedAdapter.id === adapterIds.BETPAL) {
            this.props.openBetpalBooking(bookableEventUris);
        } else {
            this.props.bookEvents(
                this.props.currentView,
                this.props.selectedAdapter.id,
                this.props.prices,
                this.props.eventsSorted,
                this.props.contentVariant,
                this.props.avQuality
            );
        }
    }

    warningOptionalContent(event) {
        const product = adapterLcProducts[this.props.selectedAdapter.id];

        if (
            product !== undefined &&
            isSubProductOptionalContent(event.products[product])
        ) {
            return this.buildBasketEventWarning(
                'Optional Content - if you book this content, you' +
                    ' agree to pay based on the rate card defined in your' +
                    ' contract'
            );
        }
        return null;
    }

    warningRestrictedCountry(event) {
        const product = adapterLcProducts[this.props.selectedAdapter.id];

        if (
            product !== undefined &&
            event.products[product] &&
            event.products[product].countriesNotAvailable.count > 0
        ) {
            return this.buildBasketEventWarning(
                "Due to geo-restriction, the event won't be available" +
                    ' in one or more of your operational countries.'
            );
        }
        return null;
    }

    warningTooLateToBook(event) {
        if (tooLateToBook(event)) {
            return this.buildBasketEventWarning(
                'An event cannot be booked more than 60 days after it has ended'
            );
        }
        return null;
    }

    buildBasketEventWarning(text) {
        return (
            <tr>
                <td colSpan={7}>
                    <div className="text basket-event-warning">{text}</div>
                </td>
            </tr>
        );
    }

    getEventRows() {
        const bookableEventUris = [];
        let showFeedSelectorDropdown = false;
        let showFeedSelectorColumn = false;
        let showHighQualityColumn = false;
        const eventRows = this.props.eventsSorted.map(event => {
            if (this._eventIsBookable(event.uri)) {
                bookableEventUris.push(event.uri);
            }

            const removeBtn = (
                <span
                    className="remove-btn"
                    data-testid="remove-btn"
                    onClick={this._removeEventFromBasket.bind(this, event.uri)}
                >
                    <FontIcon icon="" />
                </span>
            );

            const priceDisplayValues = this._getPriceColumnValues(event);

            showFeedSelectorColumn =
                this.props.is4sightEnabled &&
                [adapterIds.LCO, adapterIds.LCT, adapterIds.LCR].includes(
                    this.props.selectedAdapterId
                );
            showHighQualityColumn =
                this.props.isHighQualityEnabled &&
                [adapterIds.LCO, adapterIds.LCT, adapterIds.LCR].includes(
                    this.props.selectedAdapterId
                ) &&
                event.products['lco']?.addOns?.find(
                    addOn => addOn.name === 'HIGH_QUALITY'
                );

            showFeedSelectorDropdown =
                event.products.hasOwnProperty('lct') ||
                event.products.hasOwnProperty('lcr') ||
                event.products.hasOwnProperty('lco');

            const feedSelectorOptions = [
                {
                    label: 'Standard Feed',
                    value: 1,
                },
                {
                    label: constants.AV_4SIGHT + ' feed',
                    value: 2,
                },
                {
                    label:
                        'Both: Standard and ' + constants.AV_4SIGHT + ' feed',
                    value: 0,
                },
            ];

            const highQualityOptions = [
                {
                    label: 'Standard Quality',
                    value: 0,
                },
                {
                    label: 'High Quality',
                    value: 1,
                },
            ];

            const onSelectChange = (selectedVariant, eventUri) => {
                if (
                    _.isObject(selectedVariant) &&
                    !_.isArray(selectedVariant)
                ) {
                    this.props.setContentVariant(selectedVariant, eventUri);
                }
            };

            const onHighQualityChange = (selectedQuality, eventUri) => {
                if (
                    _.isObject(selectedQuality) &&
                    !_.isArray(selectedQuality)
                ) {
                    this.props.setQuality(
                        selectedQuality,
                        this.props.contentVariant.value,
                        eventUri
                    );
                }
            };

            const matchedVariantOrQuality = varOrQuality => {
                const matchedOption = _.find(varOrQuality, function (o) {
                    return Object.keys(o)[0] === event.uri;
                });
                return matchedOption;
            };

            const getLatencyForLO = event => {
                const addOnsForLO = event.products['lo']?.addOns?.find(
                    addOn => addOn.product === 'LO'
                );

                return addOnsForLO && addOnsForLO.value !== null
                    ? `${addOnsForLO.name} : ${addOnsForLO.value}`
                    : '';
            };

            const getDefaultVariant = event => {
                return {
                    label: 'Standard Feed',
                    value: 1,
                };
            };

            return (
                <React.Fragment key={event.uri}>
                    <tr
                        className={
                            this.props.selectedAdapter.isPriceChange &&
                            this._hasPriceChange(event.uri)
                                ? 'price-change'
                                : ''
                        }
                    >
                        <td>
                            <Event event={event} inBasket={true} />
                        </td>

                        {this.props.selectedAdapterId ===
                            adapterIds.LIVEODDS && (
                            <td className="price">
                                {getLatencyForLO(event).toLowerCase()}
                            </td>
                        )}

                        {showFeedSelectorColumn && (
                            <td className="feed-selection">
                                {showFeedSelectorDropdown && (
                                    <CustomSelect
                                        value={
                                            this.props.contentVariant.length >
                                                0 &&
                                            matchedVariantOrQuality(
                                                this.props.contentVariant
                                            )
                                                ? matchedVariantOrQuality(
                                                      this.props.contentVariant
                                                  )[event.uri]
                                                : getDefaultVariant(event)
                                        }
                                        clearable={false}
                                        optionHeight={20}
                                        searchable={false}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        isDisabled={
                                            !(
                                                event.products?.lco
                                                    ?.bookingStatusId ===
                                                    BOOKING_STATUS.BOOKABLE &&
                                                event.products.hasOwnProperty(
                                                    'lco-4sight'
                                                ) &&
                                                event.products['lco-4sight']
                                                    ?.bookingStatusId ===
                                                    BOOKING_STATUS.BOOKABLE
                                            )
                                        }
                                        options={feedSelectorOptions}
                                        onChange={selected =>
                                            onSelectChange(selected, event.uri)
                                        }
                                    />
                                )}
                            </td>
                        )}
                        {showHighQualityColumn && (
                            <td className="high-quality-selection">
                                {showFeedSelectorDropdown && (
                                    <CustomSelect
                                        value={
                                            this.props.avQuality.length > 0 &&
                                            matchedVariantOrQuality(
                                                this.props.avQuality
                                            )
                                                ? matchedVariantOrQuality(
                                                      this.props.avQuality
                                                  )[event.uri]
                                                : {
                                                      label: 'Standard Quality',
                                                      value: 0,
                                                  }
                                        }
                                        clearable={false}
                                        optionHeight={20}
                                        searchable={false}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        isDisabled={
                                            this.props?.contentVariant
                                                ?.value === 2
                                        }
                                        options={highQualityOptions}
                                        onChange={selected =>
                                            onHighQualityChange(
                                                selected,
                                                event.uri
                                            )
                                        }
                                    />
                                )}
                            </td>
                        )}
                        <td className="price">{priceDisplayValues.fee}</td>
                        <td>{removeBtn}</td>
                    </tr>
                    {this.warningOptionalContent(event)}
                    {this.warningRestrictedCountry(event)}
                    {this.warningTooLateToBook(event)}
                </React.Fragment>
            );
        });
        return {
            eventRows,
            bookableEventUris,
            showFeedSelectorColumn,
            showHighQualityColumn,
        };
    }

    getBookedStatus(bookableEventUris) {
        let message = <div></div>;
        let status = '';
        let icon = '';

        if (this.props.selectedAdapter) {
            if (this.props.selectedAdapter.isBooking) {
                if (this.props.selectedAdapter.error) {
                    status = 'error';
                    icon = '';
                    message = (
                        <div className="booking-status-label failed">
                            {this.props.selectedAdapter.failedBookingMessage}
                        </div>
                    );
                } else {
                    status = 'success';
                    icon = '';
                    message = (
                        <div className="booking-status-label success">
                            Successfully booked
                        </div>
                    );
                }
            } else if (this.props.selectedAdapter.isPriceChange) {
                status = 'warning';
                icon = '';
                message = (
                    <div className="booking-status-label price-change">
                        {
                            'The events were NOT booked due to a change in the prices. Please review the prices before trying again'
                        }
                    </div>
                );
            } else if (
                bookableEventUris.length >
                this.props.backendSettings.bookEventsLimit
            ) {
                status = 'warning';
                icon = '';
                message = (
                    <div className="booking-status-label price-change">
                        You have reached the limit of events you can book at
                        once ({this.props.backendSettings.bookMatchesLimit}).
                        Please unselect some events and try again.
                    </div>
                );
            }
        }

        return [status, icon, message];
    }

    _buildHeader(bookableEventUris) {
        // Error header
        if (
            this.props.selectedAdapter.error &&
            !this.props.basketErrorsDismissed
        ) {
            return (
                <div className="header error">
                    <FontIcon icon="" />
                    <div className="error-message">Something went wrong...</div>
                    <div>
                        <Button
                            type={Button.types.SMALL_RED_SOLID}
                            onClick={this._dismissErrorsDialog}
                            fixedWidth={false}
                        >
                            dismiss
                        </Button>
                    </div>
                </div>
            );
        }

        // Loading Header
        if (this.props.selectedAdapter.isLoading) {
            return (
                <div className="header loading">
                    <BusySpinner size={16} margin={'0'} />
                    <span>{this.props.selectedAdapter.loadingText}</span>
                </div>
            );
        }

        // Booking status header
        const [status, icon, message] = this.getBookedStatus(bookableEventUris);
        if (status) {
            return (
                <div className={`header ${status}`}>
                    <FontIcon icon={icon} />
                    {message}
                </div>
            );
        }

        // Default Header
        return (
            <div className="header">
                {this.props.children}
                <Button
                    className="book-matches-button"
                    type={Button.types.LARGE_GREEN}
                    onClick={() => this._onBookClicked(bookableEventUris)}
                    disabled={
                        bookableEventUris.length === 0 ||
                        bookableEventUris.length >
                            this.props.backendSettings.bookMatchesLimit
                    }
                    icon=""
                    fixedWidth="172px"
                >
                    BOOK MATCHES
                    {bookableEventUris.length > 0 ? (
                        <Badge
                            className="textline-300"
                            content={bookableEventUris.length}
                            color="red"
                        />
                    ) : (
                        ''
                    )}
                </Button>
            </div>
        );
    }

    render() {
        const {
            eventRows,
            bookableEventUris,
            showFeedSelectorColumn,
            showHighQualityColumn,
        } = this.getEventRows();

        return (
            <div className="shopping-basket">
                {this._buildHeader(bookableEventUris)}

                {this._buildAdapterButtonRow()}

                <div className="inner-content">
                    <div className="table-container">
                        <table className="table">
                            {this._buildTableNotAllowedCaption()}
                            <thead>
                                <tr className="table-header">
                                    <th>
                                        <div className="event-side-border">
                                            Event
                                        </div>
                                    </th>
                                    {this.props.selectedAdapterId ===
                                        adapterIds.LIVEODDS && <th>Add-ons</th>}

                                    {showFeedSelectorColumn && (
                                        <th className="feed-selection">
                                            Variant
                                        </th>
                                    )}

                                    {showHighQualityColumn && (
                                        <th className="high-quality-selection">
                                            Quality
                                        </th>
                                    )}

                                    <th>
                                        {this.props.selectedAdapter.showPrices
                                            ? 'Fee'
                                            : ''}
                                    </th>

                                    <th>
                                        <FontIcon title="close" icon="" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{eventRows}</tbody>
                        </table>
                    </div>
                    <div className="deselect-button">
                        <Button
                            onClick={this._onRemoveAllClick}
                            type={Button.types.TRANSPARENT_SOLID}
                            style={{
                                float: 'right',
                            }}
                            icon={''}
                            iconPosition="right"
                        >
                            Deselect all
                        </Button>
                    </div>
                    <div className="row price-error">
                        {this.props.selectedAdapter.error ? (
                            <>
                                <FontIcon className="error-icon" icon="" />
                                <div>
                                    In the moment, the Live Booking Calendar is
                                    not getting the actual price of the
                                    match(es). The team is working to solve the
                                    problem. Please try again in 5 minutes!
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="row disclaimer">
                        <p>
                            Please note that all booked events will be itemized
                            and invoiced on a monthly basis to the department /
                            individual that handles your accounts payable.
                        </p>
                        <p>
                            Please direct any questions to invoice@betradar.com.
                            If you have any issues or problems with bookings,
                            please contact support@betradar.com. Book now?
                        </p>
                    </div>
                    <div className="row buttons-row">
                        <Button
                            onClick={this._onCancelClick}
                            type={Button.types.LARGE_TRANSPARENT_SOLID}
                            fixedWidth={false}
                            icon={''}
                            iconPosition="right"
                        >
                            {this.props.selectedAdapter &&
                            this.props.selectedAdapter.isBooking
                                ? 'Exit'
                                : 'Cancel'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    _buildTableNotAllowedCaption() {
        if (!this.props.canBookWithCurrentAdapter) {
            return (
                <caption>
                    <Alert type={Alert.types.DANGER}>
                        You don't have sufficient privilegies to book this
                        product
                    </Alert>
                </caption>
            );
        }
        return null;
    }
}

ShoppingBasketContent.propTypes = {
    selectedAdapter: PropTypes.object.isRequired,
    prices: PropTypes.object,
    shouldFetchPrices: PropTypes.bool.isRequired,
    canBookWithCurrentAdapter: PropTypes.bool.isRequired,
    basketErrorsDismissed: PropTypes.bool.isRequired,
    eventsSorted: PropTypes.array.isRequired,
    failedBookings: PropTypes.array.isRequired,
    adapterButtons: PropTypes.array.isRequired,
    size: PropTypes.object.isRequired,
    backendSettings: PropTypes.object.isRequired,
    removeEvent: PropTypes.func.isRequired,
    removeAllEvents: PropTypes.func.isRequired,
    clearFailedBookings: PropTypes.func.isRequired,
    setIsExpanded: PropTypes.func.isRequired,
    setSelectedAdapterId: PropTypes.func.isRequired,
    selectedAdapterId: PropTypes.number,
    loadPrices: PropTypes.func.isRequired,
    bookEvents: PropTypes.func.isRequired,
    dismissErrorsDialog: PropTypes.func.isRequired,
    openBetpalBooking: PropTypes.func.isRequired,
    currentView: PropTypes.string,
    children: PropTypes.node,
    setContentVariant: PropTypes.func,
    setQuality: PropTypes.func,
    contentVariant: PropTypes.array,
    avQuality: PropTypes.array,
    is4sightEnabled: PropTypes.bool.isRequired,
    isHighQualityEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    canBookLiveodds: state.shoppingBasket.canBookLiveodds,
    canBookLiveChannel: state.shoppingBasket.canBookLiveChannel,
    canBookBetpal: state.shoppingBasket.canBookBetpal,
    selectedAdapter: selectedAdapter(state.shoppingBasket),
    selectedAdapterId: state.shoppingBasket.selectedAdapterId,
    shouldFetchPrices: shouldFetchPrices(state.shoppingBasket),
    canBookWithCurrentAdapter: canBookWithCurrentAdapter(state.shoppingBasket),
    prices: state.shoppingBasket.pricesByAdapterId[
        state.shoppingBasket.selectedAdapterId
    ],
    basketErrorsDismissed: state.shoppingBasket.errorsDismissed,
    eventsSorted: eventsSorted(state.shoppingBasket),
    failedBookings: state.shoppingBasket.failedBookings,
    currentView: state.viewport.currentView,
    adapterButtons: adapterButtons(state.calendar),
    size: state.calendar.size,
    backendSettings: state.calendar.backendSettings,
    is4sightEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.enable_av_4Sight,
    isHighQualityEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_av_high_quality,
    contentVariant: state.shoppingBasket.contentVariant,
    avQuality: state.shoppingBasket.avQuality,
});

const mapDispatchToProps = {
    removeEvent,
    removeAllEvents,
    clearFailedBookings,
    setIsExpanded,
    setSelectedAdapterId,
    loadPrices,
    bookEvents,
    dismissErrorsDialog,
    openBetpalBooking,
    setContentVariant,
    setQuality,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingBasketContent);
